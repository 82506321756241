import {
  Box,
  BoxProps,
  Flex,
  Container,
  IconButton,
  Icon,
  HStack,
  Button,
} from '@chakra-ui/react';
import Image from 'next/image';
import NextLink from 'next/link';

import ModalTrigger from '@inspire/ui/chakra/modal/ModalTrigger';
import MobileLogoSrc from '@inspire/ui/assets/original.logo.svg';
import { LeftArrowIcon, SearchIcon } from '@inspire/ui/icons';
import Link from 'components/general/Link';
import DrawerButton from 'components/layout/DrawerButton';
import UserMenu from 'components/layout/UserMenu';
import SearchModalContent from 'components/search/SearchModalContent';
import {
  useIsEclaireurProfileComplete,
  useIsQuestionnaireComplete,
  useUser,
} from 'lib/hooks';
import {
  CONNEXION_PATH,
  // INSCRIPTION_PATH,
  LOGGED_IN_HOME_PATH,
  LOGGED_OUT_HOME_PATH,
} from 'lib/paths';
import { useMemoryStore } from 'lib/store';
import { hideIfNarrowerThan } from 'styles/style-utils';
import { roundButtonProps } from '@inspire/ui/chakra/theme';
import { DemoTooltip, isDemo } from 'components/auth/DemoLogin';
import {
  GTM_ACTION_INSCRIPTION,
  GTM_CATEGORY_INSCRIPTION,
  pushEvent,
} from 'lib/gtm';
import { accessSSO } from 'lib/sso';

const BackButton = () => {
  const backButton = useMemoryStore((s) => s.backButton);

  return backButton?.path ? (
    <NextLink href={backButton?.path} passHref>
      <IconButton
        sx={hideIfNarrowerThan('360px')}
        aria-label={backButton?.label ?? 'Retour'}
        icon={<Icon as={LeftArrowIcon} boxSize={6} />}
        variant="ghost"
      />
    </NextLink>
  ) : null;
};

// This header shows a logged-out state on load, which causes
// a layout shift on AppLayout pages. We could pass a prop
// from AppLayout > MobileLayout > MobileHeader to force showing the
// logged-in version, since we know the user is logged in there.
const MobileHeader = (boxProps: BoxProps & { showLoginButton: boolean }) => {
  const { user } = useUser();
  const isQuestionnaireComplete = useIsQuestionnaireComplete();
  const isEclaireurProfileComplete = useIsEclaireurProfileComplete();
  const { showLoginButton, ...rest } = boxProps;

  // Get the redirect URL from the store for redirection after login
  const redirectTargetUrl = useMemoryStore((s) => s.redirectTargetUrl);
  const redirectUrlFromStore = redirectTargetUrl?.redirectUrl;

  return (
    <Box
      boxShadow="0 1px 8px rgb(0 0 0 / 10%)"
      zIndex={3}
      background="white"
      position="fixed"
      top={0}
      w="100%"
      {...rest}
    >
      <Container maxW="container.xl" py={[2, 3]}>
        <Flex justifyContent="space-between">
          <HStack wrap="nowrap">
            <DrawerButton />
            <BackButton />
            <Link
              href={user ? LOGGED_IN_HOME_PATH : LOGGED_OUT_HOME_PATH}
              lineHeight={0}
              h={5}
              textAlign="center"
              sx={{ 'span, img': { height: '100% !important' } }}
              mb={1}
            >
              <Image
                alt="Logo INSPIRE"
                src={MobileLogoSrc}
                width={120}
                height={26}
              />
            </Link>
          </HStack>
          <HStack wrap="nowrap">
            {isQuestionnaireComplete && isEclaireurProfileComplete && (
              <ModalTrigger
                trigger={(openModal) => (
                  <IconButton
                    variant="ghost"
                    aria-label="Recherche"
                    color="black"
                    icon={<Icon boxSize={6} as={SearchIcon} />}
                    onClick={openModal}
                    sx={hideIfNarrowerThan(user ? '280px' : '375px')}
                  />
                )}
                modalProps={{ size: '2xl' }}
              >
                {(closeModal) => <SearchModalContent closeModal={closeModal} />}
              </ModalTrigger>
            )}
            {!user && (
              <>
                {showLoginButton && (
                  <DemoTooltip>
                    <Button
                      isDisabled={isDemo}
                      {...roundButtonProps}
                      onClick={() => {
                        pushEvent(
                          GTM_CATEGORY_INSCRIPTION,
                          GTM_ACTION_INSCRIPTION,
                          "Je m'inscris (CTA 1)"
                        );
                        accessSSO({
                          targetUrl: redirectUrlFromStore
                            ? `${CONNEXION_PATH}?redirectUrl=${redirectUrlFromStore}`
                            : `${CONNEXION_PATH}?redirectUrl=${LOGGED_IN_HOME_PATH}`,
                        });
                      }}
                    >
                      Connexion | Inscription
                    </Button>
                  </DemoTooltip>
                )}
              </>
            )}
            {user && <UserMenu />}
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};

export const MobileHeaderOnMobile = ({ showLoginButton = true }) => (
  <MobileHeader
    display={['block', null, 'none']}
    showLoginButton={showLoginButton}
  />
);

export default MobileHeader;

import {
  Box,
  BoxProps,
  Heading,
  Container,
  Center,
  Image as ChakraImage,
  Stack,
  Text,
  Link as ChakraLink,
  Button,
  VStack,
} from '@chakra-ui/react';

import Row from '@inspire/ui/chakra/layout/Row';
import cdcSrc from '@inspire/ui/assets/cdc.svg';
import investirlavenirSrc from '@inspire/ui/assets/investirlavenir.png';

import Link from 'components/general/Link';
import { MENTIONS_LEGALES_PATH, QUI_SOMMES_NOUS_PATH } from 'lib/paths';
import { useResetDemo } from 'lib/hooks';
import { sansFonts } from '@inspire/ui/static';
import { roundButtonProps } from '@inspire/ui/chakra/theme';
import NoWrap from 'components/landing-pages/NoWrap';
import Image from 'next/image';

interface Props {
  variant?: 'dark' | 'gray';
  isLight?: boolean;
}

const Footer = ({
  variant = 'dark',
  isLight = false,
  ...boxProps
}: Props & BoxProps) => {
  const { resetDemo, isResettingDemo } = useResetDemo({
    onSuccess: () => {
      alert('Le site de démo a été réinitialisé');
      window.location.reload();
    },
    onError: () => alert("Cela n'a pas fonctionné"),
  });

  const textColor = variant === 'dark' ? 'white' : 'gray.700';

  return (
    <Row
      variant={variant}
      boxShadow={variant === 'dark' && '0 -5px 10px rgba(0, 0, 0, 0.2) '}
      color={textColor}
      borderTop={variant !== 'dark' && '1px solid #ddd'}
      bgColor={variant !== 'dark' ? 'white' : '#111'}
      py={isLight ? 6 : 12}
      {...boxProps}
    >
      <Container maxW="container.xl">
        {!isLight && (
          <Stack direction={['column', null, 'row']} spacing={6}>
            <Box width={['100%', null, '50%']}>
              <Heading
                as="h4"
                size="sm"
                color={textColor}
                textAlign="center"
                fontWeight="normal"
                mt={0}
                fontFamily={sansFonts}
                textTransform="uppercase"
              >
                Créé par
              </Heading>
              <Center my={8}>
                <ChakraLink href="https://article-1.eu/" isExternal>
                  <ChakraImage
                    w="full"
                    maxW="200px"
                    src={
                      variant === 'dark'
                        ? '/img/landing-pages/logo-article1.svg'
                        : '/img/landing-pages/logo-article1-black.png'
                    }
                    alt="Logo Article 1"
                  />
                </ChakraLink>
              </Center>

              <Text color={textColor}>
                Guidé par des valeurs communes de justice, d’égalité, de
                fraternité et de liberté,{' '}
                <Link href="https://article-1.eu/" color={textColor}>
                  <NoWrap>Article 1</NoWrap>
                </Link>{' '}
                a développé au fil du temps des programmes qui font appel aux
                mêmes leviers : tisser des liens et organiser des rencontres
                entre ces jeunes et des volontaires issus du monde
                professionnel, désireux de partager leurs savoirs et leur
                engagement.
              </Text>

              <Stack
                spacing={6}
                direction={['column-reverse', null, null, 'row']}
                align="center"
                mb={[5, null, 0]}
              >
                <Stack direction={['column', 'row']} spacing={5}>
                  <Center boxSize="100px" flexShrink={0}>
                    <Image
                      alt="Logo Caisse des Dépôts"
                      src={cdcSrc}
                      width={90}
                      height={90}
                    />
                  </Center>
                  <Center boxSize="100px" flexShrink={0}>
                    <Image
                      alt="Logo Investir L'Avenir"
                      src={investirlavenirSrc}
                      width={100}
                      height={100}
                    />
                  </Center>
                </Stack>
                <Text>
                  INSPIRE est une opération soutenue par L'État dans le cadre de
                  l'action « Territoires d'innovation pédagogique » du Programme
                  d'investissements d'avenir, opéré par la Caisse des Dépôts.
                </Text>
              </Stack>
            </Box>

            <Box width={['100%', null, '50%']}>
              <Heading
                as="h4"
                size="sm"
                fontWeight="normal"
                textAlign="center"
                mt={0}
                fontFamily={sansFonts}
                textTransform="uppercase"
                color={textColor}
              >
                Nos autres projets
              </Heading>
              <Stack
                direction={['column', 'row']}
                justify="space-evenly"
                spacing={[10, null, 5]}
                mt={[0, null, '80px']}
              >
                <VStack flexBasis={['2rem', '12rem']}>
                  <ChakraLink
                    href="https://www.dema1n.org/"
                    isExternal
                    textDecor="none !important"
                  >
                    <Center h="10rem" maxW="10rem" bgColor="white" mb={3}>
                      <ChakraImage
                        w="100%"
                        src="/img/landing-pages/logo-dema1n.svg"
                        alt="Logo Dema1n"
                      />
                    </Center>
                  </ChakraLink>
                  <Box>
                    <ChakraLink
                      href="https://www.dema1n.org/"
                      isExternal
                      textDecor="none !important"
                    >
                      <Button {...roundButtonProps}>
                        Je construis mon avenir
                      </Button>
                    </ChakraLink>
                  </Box>
                </VStack>
                <VStack flexBasis={['2rem', '12rem']}>
                  <ChakraLink
                    href="https://www.jobready.fr/"
                    isExternal
                    textDecor="none !important"
                  >
                    <Center h="10rem" maxW="10rem" bgColor="white" mb={3}>
                      <ChakraImage
                        w="100%"
                        src="/img/landing-pages/logo-jobready.svg"
                        alt="Logo Jobready"
                      />
                    </Center>
                  </ChakraLink>
                  <Box>
                    <ChakraLink
                      href="https://www.jobready.fr/"
                      isExternal
                      textDecor="none !important"
                    >
                      <Button {...roundButtonProps}>Je trouve un job</Button>
                    </ChakraLink>
                  </Box>
                </VStack>
              </Stack>
            </Box>
          </Stack>
        )}

        <Stack
          direction={['column', 'row']}
          mt={isLight ? 0 : 12}
          justify="space-between"
          spacing={[8, 2]}
        >
          <Box textAlign={['center', 'left']}>
            © {new Date().getFullYear()}
            <NoWrap> Article 1</NoWrap>
          </Box>

          <Stack
            direction={['column', 'row']}
            spacing={[2, 6]}
            align="flex-start"
          >
            <Link
              href={QUI_SOMMES_NOUS_PATH}
              textDecoration="none"
              color={textColor}
              display="inline"
            >
              Qui sommes nous ?
            </Link>
            <Link
              href={MENTIONS_LEGALES_PATH}
              textDecoration="none"
              color={textColor}
            >
              Mentions légales
            </Link>
            <Link
              href="https://connect.article-1.eu/legals/conditions-generales"
              textDecoration="none"
              color={textColor}
              isExternal
            >
              CGU
            </Link>
            <Link
              href="https://connect.article-1.eu/legals/politique-confidentialite"
              textDecoration="none"
              color={textColor}
              isExternal
            >
              Politique de Confidentialité
            </Link>
          </Stack>
        </Stack>
        {process.env.NEXT_PUBLIC_STAGE === 'demo' && (
          <Center>
            <Button onClick={resetDemo} isLoading={isResettingDemo}>
              Réinitialiser le site de démo
            </Button>
          </Center>
        )}
      </Container>
    </Row>
  );
};

export default Footer;

var UserStatus = {
    // default status
    active: "actif",
    // can access site, but not visible to other users
    hidden: "cach\xe9",
    // can access site, but only visible through he profile URL
    ancien: "ancien",
    // can't access site
    deleted: "supprim\xe9",
    // only use for admins
    inactive: "inactif"
};
export { UserStatus };
